.card{
  min-height: 200px;
}
.card .shell-text-label{
    font-size: 16px;
    color: rgb(52, 52, 52);
    line-height: 20px;
  }
  .card .update-text{
    color: rgb(117, 117, 117);
  }
  .logs .logs-text{
    font-weight: 600;
    line-height: 24px;
  }
  .card .output-footer{
    margin-top: 20px;
  }
  .card .action-button {
    margin-left: 10px;
    font-size: 12px;
  }

  .coming-soon {
    text-align: center;
    animation: fade 2s infinite;
  }

@keyframes fade {
    0%, 100% {
        color: #1c8bf3; /* Start and end color (black) */
    }
    50% {
        color: transparent; /* Middle of the animation (invisible) */
    }
}
.table-container {
  overflow: auto;
  max-height: 400px;
  box-shadow: 0px 0px 2px 2px rgb(243, 238, 238);
  border-radius: 3px;
}
.table-container table {
  width: 100%;
  border-collapse:unset;
  border-spacing: 0;
}
.table-container thead tr th {
  background: #F5F5F5;
  padding: 14px 10px 14px 10px;
  font-size: 16px;
  position: sticky;
  top: 0;
}
.table-container tbody tr td {
  padding: 7px 0px 7px 9px;
  max-height: 5px;
  font-size: 0.8125rem;
  font-weight: 400;
  font-family: 'Shell Font', Arial, sans-serif;
  line-height: 22px;
  color: rgb(64, 64, 64);
}

.linktotanso {    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #32619f; 
    cursor: pointer;   
}

.DataVisual {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: space-around;
    height: 100%;
    width: 100%;
    background: #f5f5f5;
}
.displayChart {
    display: 'flex';
    width: '100%';
    padding: '15px 15px';
    gap: '7px';
}
.dashboard-footer {    
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(201, 201, 201, 0.5);
    font-family: 'Shell Font', Arial, sans-serif;    
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    left:448px;
    color: #757575;
    margin-bottom: -42px;  
    padding-top: 25px;
    word-wrap: break-word;
    white-space: pre-wrap;
}


.copy-icon {
  cursor: pointer;
  padding-right: 8px;
}
.imported-deals-modal .shell-modal-container-title > div {
  display: block;
}
.imported-deals-modal .shell-modal-container-title > div > div {
  gap: 0px;
}
.imported-deals-table{
  width: 100%;
}
.imported-deals-modal .imported-deals-table {
  margin: 0 20px 20px;
}
.imported-deals-table tfoot tr {
  background-color: white;
}

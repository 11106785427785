.btn-style.link-btn {
  width: auto;
  border: none;
  min-height: 30px;
  font-size: 14px;
  padding: 0 8px 0 8px;
  background-color: transparent;
  transition: text-decoration 0.3s, background-color 0.3s;
}
.btn-style.link-btn:hover {
  text-decoration: underline;
  background-color: transparent;
  border: none;
}
.mainGrid {
  height: 100%;
  background: rgb(245, 245, 245);
}
.chartFlex {
  margin-left: '0px';
  justify-content: 'start';
  padding: 0 20px 0 20px;
}
.chartContainer {
  width: 100%;
  height: 600px;
  padding-top: 20px;
  margin-bottom: 0px;
}



.filterName {            
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #404040;
    padding: 10px 0;    
}

.chartContainer {    
    width: 800px;
    height: 600px;
    padding-top: 20px;     
    margin-bottom:10px; 
}

.toggleButton {       
    z-index: 10000;    
    background-color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    padding: 6px 6px;
    cursor: pointer;
    margin-top: 0.1em;
}

.toggleButtonDiv {
    display: flex;
    justify-content: space-between;
    float: right;
    padding-right: 90px;
}


.toggleText {
    margin-top: 7px;
}

.filterName {            
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #404040;
    padding: 10px 0;    
}

.chartContainerWrapper {       
    padding-top: 20px;     
    margin-bottom:10px;         
}

.header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: rgb(0, 0, 0);
    margin-top: 10px;    
}

.footer-notes {    
    font-style: italic;
    color: #757575;
    font-size: 12px;
    margin-top: -7px;
    background: #FFFFFF;
    padding: 5px;        
}  

.footerNotesRen {    
    font-style: italic;
    color: #757575;
    font-size: 12px;
    margin-top: -7px;  
    background: #FFFFFF;
    padding: 5px;    
}
.showMCR {
  display: flex;
  margin-left: 16px;
  margin-right: 7%;
}

.editIcon {
  cursor: pointer;
  padding: 0px 14px 10px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #404040;
}
.editIcon:hover {
  background-color: transparent !important;
}

.mcrContainer {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  height: 50px;
  width: 100%;
}

.statuscolor {
  background-color: #008000;
  border-radius: 2px;
  width: 8px;
}

.mcrvaluesbox {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.m_c_r_values {
  font-weight: bold;
}

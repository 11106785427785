.header-global .tabMenu {
    left: 30px;
    width: 800px;
  }
  .header-global .tabMenu .shell-menu-submenu-title,
  .header-global .tabMenu .shell-menu-item {
    padding: 6px;
  }
  .shell-nav-bar div:nth-child(2) > div {
    justify-content: flex-start;
  }
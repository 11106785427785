.class1 {
    border:2px solid rgba(0,0,0,0.15);
    border-radius: 10px;
  }

.masterOption{
  border: 3px solid #F7CF9B
}
.report-style-class {
    height:100%;
    width:100%;
    left:0;
    top:0;
  }

.getDataBtn {
  margin: 10px;
}

.snapshotTableCols { 
  border: 2px solid #f4f4f4
}
.shell-table-content > table > thead > tr > th{
  background-color: #32619F !important;
  color: white !important;
}

.label{
  margin-right: 10px;
}

.conatainer {
  position: relative;
}

.elements {
  position: absolute
}

.editReportPageActionBtns {
  height: 50px;
  margin-top: 35px !important;
}

.nonEditableCols {
  background-color: #eae6e6 !important;
  border: 2px solid #f4f4f4
}

tr.confirmSnapshotApprovedRow {
  background-color: #8ef4b7 !important;
}

.confirmSnapshotRow {
  background-color: #d3d3d1 !important;
}

.fieldName{
  max-width: 100px;
  height: 40px;
}

.originalVal{
  border: 3px solid #F7CF9B;
  max-width: 120px;
  height: 40px;
}

.updatedVal{
  border: 3px solid #8ef4b7;
  max-width: 120px;
  height: 40px;
}

fieldset {
  border: 1px solid #ded8d8;
  border-radius: 4px;
  height: auto;  
  display: flex;
}
legend {
  padding: 2px 4px;
  background: #fff;
  /* For better legibility against the box-shadow */
}

.confirmSnapshotGenerateMessage {
  margin : 20px 0px;
}

.mainGrid {
  padding-bottom: '10px'
}

.sectoralCard {
  color: 'pink !important'
}

